<!-- 电子准考证 -->
<template>
  <div class="ElectricCard">
    <div style="margin: 50px auto" @click="copy(tableData[5].value)">
      {{ tableData[5].value }}
    </div>
    <block v-if="false">
      <div class="top">中国美术学院社会美术水平考级中心准考证</div>
      <!-- 相片 -->
      <div class="pic">
        <img :src="head_image" />
      </div>
      <!-- 表格 -->
      <div class="table">
        <el-table :data="tableData" border :show-header="false">
          <el-table-column prop="key" width="100"> </el-table-column>
          <el-table-column prop="value" align="center"> </el-table-column>
        </el-table>
        <!-- 印章 -->
        <div class="seal">
          <img src="../../assets/zhang_1.png" />
        </div>
      </div>
      <!-- 考生须知 -->
      <div class="Notice">
        <div
          class=""
          style="padding-left: 10px; padding-bottom: 10px; font-weight: bold"
        >
          考生须知
        </div>
        <div class="Notice-text">
          1.考卷由中国美术学院考级专家委员会统一命题,在考级中心统一评卷,之后统一发证。<br />
          2.考生拿到准考证后要仔细核对,如有疑问请与学院考级办联系。<br />
          3.考生须持准考证并按照准考证上规定的时间、地点和考场参加考试,遵守考试纪律,家长一律不得进入考场,无准考证或准考证与本人身份不符者不得进入考场。<br />
          4.素描、色彩考生应自带画板或画夹,考生根据考纲带好考试用工具,除考试用具外,考生将其余所带的物品,交监考老师集中保管(贵重物品除外),考试用纸由考级办统一配发。<br />
          5.考生需再次核对准考证号码、姓名、报考科目和级别等信息准确无误。废卷不得带出考场。<br />
          6.考级机构对考级作品有展览、研究、摄影、录像、出版、宣传和处置权,考级作品一律不退。
        </div>
      </div>
      <div class="box"></div>
    </block>
  </div>
</template>

<script>
export default {
  data() {
    return {
      head_image: "", //头像
      tableData: [
        { key: "姓名", value: "" },
        { key: "身份证号码", value: "" },
        { key: "性别", value: "" },
        { key: "国籍", value: "" },
        { key: "民族", value: "" },
        { key: "准考证号", value: "" },
        { key: "专业", value: "" },
        { key: "级别", value: "" },
        { key: "考场地址", value: "" },
        { key: "机构名称", value: "" },
        { key: "考试时间", value: "" },
      ],
    };
  },
  created() {
    // 获取学科id
    let href = window.location.href;

    // console.log(this.$route.query.signup_id,this.$route.query.uid)

    if (href.indexOf("?") != -1) {
      // href.slice(href.indexOf('?'),href.length-1)
      let signup_id = href.slice(href.indexOf("?") + 1, href.length);
      // console.log(signup_id)
      if (signup_id.indexOf("=") != -1) {
        let id = signup_id.slice(
          signup_id.indexOf("=") + 1,
          signup_id.length + 1
        );
        // console.log(id)
        // localStorage.setItem('signup_id', id)
      }
    }

    // 请求数据
    this.$request({
      url: "/api/studentexam/admission",
      method: "POST",
      data: {
        // uid: localStorage.getItem('token'),
        // signup_id:localStorage.getItem('signup_id')
        uid: this.$route.query.uid,
        signup_id: this.$route.query.signup_id,
      },
    }).then((res) => {
      console.log(res);
      let data = res.data;
      let table = this.tableData;
      this.head_image = data.head_image; //头像
      table[0].value = data.username; //姓名
      table[1].value = data.idcard; //身份证
      table[2].value = data.gender; //性别
      table[3].value = data.nation2; //国籍
      table[4].value = data.nation; //民族
      table[5].value = data.certificate_no; //准考证号
      table[6].value = data.course; //专业
      table[7].value = data.exam_level; //级别
      table[8].value = data.exam_address; //考场地址
      table[9].value = data.org_name; //机构名称
      table[10].value = data.exam2_date; //考试时间
    });
  },
  methods: {
    //点击复制ID
    copy(value) {
      // 创建一个input 元素
      // createElement() 方法通过指定名称创建一个元素
      let newInput = document.createElement("input");
      // 讲存储的数据赋值给input的value值
      newInput.value = value;
      // appendChild() 方法向节点添加最后一个子节点。
      document.body.appendChild(newInput);
      // 选中input元素中的文本
      // select() 方法用于选择该元素中的文本。
      newInput.select();
      // 执行浏览器复制命令
      //  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
      document.execCommand("Copy");
      // 清空输入框
      newInput.remove();
      // 下面是element的弹窗 不需要的自行删除就好
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
  },
};
</script>

<style scoped="scoped">
.ElectricCard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* 头部 */
.top {
  width: 100%;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

/* 相片 */
.pic {
  width: 100%;
  text-align: center;
}

.pic img {
  width: 140px;
  height: 170px;
  border: 1px solid #ccc;
}

.table {
  margin-top: 30px;
  width: 100%;
}

.table .el-table {
  width: 350px;
  margin: 0px auto;
  text-align: center;
  position: relative;
}
.el-table--border {
  border: 1px solid #333333;
}
::v-deep .el-table td.el-table__cell {
  border-right: 1px solid #333333;
  border-bottom: 1px solid #333333;
}
::v-deep .el-table__cell {
  color: #000;
  padding: 0;
  height: 35px;
}
/* 印章 */
.seal {
  position: absolute;
  top: 70%;
  right: 20px;
  z-index: 222;
}
.seal img {
  width: 190px;
  height: 128px;
}
.table table th {
  width: 120px;
  height: 35px;
  line-height: 35px;
  padding-left: 10px;
  text-align: left;
}
/* 考生须知 */
.Notice {
  width: 100%;
  margin: 20px 0px;
  padding-bottom: 20px;
}
.Notice-text {
  padding: 0px 10px;
  font-size: 14px;
  line-height: 25px;
}
</style>
